<template>
	<div class="full-height  size-px-14">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div
				v-if="is_manager && !is_finish"
				class="save"
			>
				<button
					class="btn_save"
					@click="onMore"
				><img :src="require('@/assets/image/icon_more_white.svg')"></button>
			</div>
		</div>
		<div
			class="mt-50 position-relative overflow-hidden"
			style="height: 250px"
		>
			<div
				class="full-height"
				style="height: 250px"
			>
				<div
					v-for="(image, i_index) in item.vote_thumbnail_img.vote_thumbnail_img_partial_list"
					:key="'image_' + i_index"
					:class="{ 'full-height' : item.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }"
				>
					<img
						:src="image.vote_thumbnail_img_url"
						:class="{ 'object-cover' : item.vote_thumbnail_img.vote_thumbnail_img_partial_list.length == 1 }"
						class="width-100"
						@error="$bus.$emit('onErrorImages', $event)"
					/>
				</div>
			</div>
			<div
				class="position-absolute-full flex-column justify-space-between color-white"
				style="height: 250px"
			>
				<div class="pa-20 full-height text-center flex-column justify-center" style=" height: 250px; background-color: rgba( 0, 0, 0, 0.3 );">
					<div class="size-px-16 font-weight-600">{{ item.cartl_vote_base_info.vote_title | maxLength(30, '...')}}</div>


					<hr class="top-line width-40 ma-auto mt-10 mb-10" />

					<div class="size-px-13">{{ sDate }} ~ {{ eDate }}</div>
				</div>
				<div class="pa-20 mt-auto color-red font-weight-600 justify-space-between" style="  background-color: rgba( 0, 0, 0, 0.7 );">
					<div>{{ item.cartl_vote_base_info.cartl_vote_state_code_name }}</div>
					<div class="color-gray"><img :src="require('@/assets/image/icon_voting_count.svg')" /> {{ item.cartl_vote_base_info.vote_participation_count | makeComma }}</div>
				</div>
			</div>
		</div>
		<div
			class="pa-30-20"
		>
			{{ item.cartl_vote_base_info.vote_contents }}

			<div
				v-if="item.cartl_vote_base_info.plulity_choice_fg == 'Y'"
				class="mt-20 color-gray"
			>* {{ text_choice_info }}</div>
		</div>
		<div
			class="top-line "
		>
			<ul>
				<li
					v-for="(vote, v_index) in cartl_vote_optiop_list"
					:key="'vote_' + v_index"
					class="pa-10 "
				>
					<div
						class="justify-space-between gap-10 items-center"
					>
						<div class="square-64 radius-10"><img :src="vote.optiop_original_img.optiop_original_img_url" class="object-cover width-100" @error="$bus.$emit('onErrorImage', $event)"/></div>
						<div class="text-left flex-1">
							<div class="justify-start items-center gap-10">
								<button
									v-if="is_vote && !is_finish"
									class="mr-5"
									@click="setVote(vote)"
								>
									<v-icon
										v-if="vote.is_check"
										class="color-blue"
									>mdi-checkbox-marked-circle</v-icon>
									<v-icon
										v-else
									>mdi-checkbox-blank-circle-outline</v-icon>

								</button>

								<span
									v-if="is_finish"
									class="vote-rank-tier"
									:class="'vote-rank-tier-' + vote.optiop_rank"
								>{{ vote.optiop_rank }}</span>

								{{ vote.optiop_title }}
							</div>
							<div>
								<div class="justify-space-between">
									<span
										:class="'vote-rank-' + vote.optiop_rank"
									>{{ item.cartl_vote_base_info.vote_partprate_mark_code == 'CA03800002' ? vote.optiop_partprate + '%' : vote.optiop_vote_count + '표' }}</span>
									<button
										@click="toDetail(vote)"
										class="mr-10"
									>{{ vote.option_name }}
									</button>
								</div>
								<div>
									<progress-bar
										:completed-steps="vote.optiop_partprate * 1"
										:total-steps="100"
										:show-tip="false"
										innerStrokeColor="#E9E9E9"
										:startColor="vote.optiop_rank == 1 ? '#DC505C' : vote.optiop_rank == 2 ||  vote.optiop_rank == 3 ? '#3E47B7' : '#888888'"
										:stopColor="vote.optiop_rank == 1 ? '#DC505C' : vote.optiop_rank == 2 ||  vote.optiop_rank == 3 ? '#3E47B7' : '#888888'"

										style="width: 100% !important;"
									/>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div
			class="btn_area pa-10 mt-10"
			v-if="is_vote"
		>
			<button
				class="btn_l btn_fill_blue"
				:disabled="is_disabled"
				@click="postVote"
			>{{  vote_title }}</button>
		</div>
		<div
			v-else-if="is_finish"
			class="btn_area pa-10 mt-10"
		>
			<button
				class="btn_l btn_fill_red"
			>{{ $language.voting.txt_voting_period }}</button>
		</div>

		<Comment_list

			v-if="is_comment"
			:user="user"
			:cartel="item_cartel"
			type="voting"

			@cancel="update"
		></Comment_list>

		<PopupHalf
			v-if="is_on_more"
			:user="user"
			:title="'투표 '"

			@cancel="is_on_more = false"
		>
			<template
				v-slot:half_items
			>
				<li v-if="is_possible_modify" class="pb-10" @click="toEdit">{{ $language.voting.title_voting_modify}} <!-- 투표 수정 --></li>
				<li v-if="is_possible_delete" class="pb-10" @click="onDelete">{{ $language.voting.title_voting_delete}} <!-- 투표 삭제 --></li>
				<li v-if="is_possible_finish" @click="onFinish">{{ $language.voting.sub_voting_finish}} <!-- 투표 종료 --></li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_delete"

			@cancel="is_on_delete = false"
			@click="postDelete"
		>
			<template v-slot:title>{{ $language.voting.title_voting_delete }}</template>
			<template
				v-slot:main-txt
			>
				<div :inner-html.prop="$language.voting.txt_voting_delete | nl2br"></div>
			</template>
			<template
				v-slot:name-confirm
			>{{ $language.common.delete }}
			</template>

		</Popup_confirm>

		<Popup_confirm
			v-if="is_on_finish"

			@cancel="is_on_finish = false"
			@click="postFinish"
		>
			<template v-slot:title>{{ $language.voting.title_voting_finish }}</template>
			<template
				v-slot:main-txt
			>
				<div :inner-html.prop="$language.voting.txt_voting_delete2 | nl2br"></div>
			</template>
			<template
				v-slot:sub-txt
			>
				<div :inner-html.prop="$language.voting.txt_voting_delete1 | nl2br"></div>
			</template>
			<template
				v-slot:name-confirm
			>{{ $language.voting.sub_voting_finish }}
			</template>

		</Popup_confirm>
	</div>
</template>

<script>
import ProgressBar from "vue2-progress-bar";
import PopupHalf from "@/view/Layout/PopupHalf";
import Popup_confirm from "@/view/Layout/PopupConfirm";
import Comment_list from "@/view/CartelComment/mafiaCommentList";
	export default {
		name: 'CartelVotingInfo'
		, components: {Comment_list, Popup_confirm, PopupHalf, ProgressBar}
		, props: ['user']
		, data: function(){
			return {
				program: {
					title: this.$language.voting.title_voting
					, name: this.$language.voting.title_voting
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, item_voting: {

				}
				, item: {
					cartl_vote_base_info: {
						vote_startdt: ''
						, vote_enddate: ''
					}
					, vote_thumbnail_img: {}
					, cartl_vote_optiop_list: []
					, requester_vote_participation_grade_unable_fg: ''
				}

				, completedSteps: 0
				, totalSteps: 0
				, item_cartel: {

				}
				, item_more: {

				}
				, is_on_comment: false
				, is_on_more: false
				, is_on_delete: false
				, is_on_finish: false
			}
		}
		, computed: {
			is_manager: function(){
				let t = false
				if(this.item_voting.requester_cartl_member_grade_code == 'CA02500001' || (this.item_voting.requester_cartl_member_grade_code == 'CA02500002' && this.item_voting.admin_authority_settup_fg == 'Y')){
					t = true
				}

				return t
			}
			, sDate: function(){
				let t = ''
				t = this.item.cartl_vote_base_info.vote_startdt.replace('T', ' ').slice(0, 16)
				return t
			}
			, eDate: function(){
				let t = ''
				t = this.item.cartl_vote_base_info.vote_enddate.replace('T', ' ').slice(0, 16)
				return t
			}
			, cartl_vote_optiop_list: function(){
				console.log('cartl_vote_optiop_list', this.item.cartl_vote_optiop_list)
				if(this.is_finish){
					let d = this.item.cartl_vote_optiop_list
					d.sort( (a, b) => {
						if(a.optiop_rank > b.optiop_rank){
							return 1
						}
						if(a.optiop_rank < b.optiop_rank){
							return -1
						}
						if(a.optiop_rank == b.optiop_rank){
							return 0
						}
					})
				}
				return this.item.cartl_vote_optiop_list.filter( (item) => {
					if(item.optiop_info_use_fg == 'Y'){
						item.option_name = this.$language.voting.title_voting_detail_contents
					}else{
						if(this.item.cartl_vote_base_info.anoyms_vote_fg == 'Y'){
							item.option_name = ''
						}else{
							item.option_name = this.$language.voting.title_voting_detail_members
						}
					}
					return item
				})
			}
			, is_disabled: function(){
				let t = true
				let d = false

				if(this.item.requester_vote_participation_grade_unable_fg != 'Y'){
					this.item.cartl_vote_optiop_list.filter( (vote) => {
						if(vote.is_check){
							d = true
						}
					})
					if(d){
						t = false
					}
				}
				if(this.item.requester_vote_participation_compl_fg == 'Y'){
					t = true
				}
				return t
			}
			, vote_title: function(){
				let t = this.$language.voting.title_vote_doing
				if(this.item.requester_vote_participation_compl_fg == 'Y'){
					t = this.$language.voting.title_vote_complete
				}
				return t
			}
			, member_vote_optiop_choice_number_list: function(){
				let t = []
				this.item.cartl_vote_optiop_list.filter( (item) => {
					if(item.is_check){
						t.push({
							optiop_number: item.cartl_vote_optiop_number
						})
					}
				})
				return t
			}
			, is_vote: function(){
				let t = false
				if(this.item.cartl_vote_base_info.cartl_vote_state_code == 'CA03700002'){
					t = true
				}
				return t
			}
			, text_choice_info: function(){
				let t = ''
				if(this.item.cartl_vote_base_info.plulity_choice_fg == 'Y'){
					t = '중복 선택 가능'
				}
				return t
			}
			, is_finish: function(){
				let t = false
				if(this.item.cartl_vote_base_info.cartl_vote_state_code == 'CA03700006'){
					t = true
				}
				return t
			}

			, is_possible_modify: function(){
				let t = false
				if(this.item.cartl_vote_base_info.cartl_vote_state_code == 'CA03700001'){
					t = true
				}
				return t
			}
			, is_possible_delete: function(){
				let t = false
				if(this.item.cartl_vote_base_info.cartl_vote_state_code == 'CA03700001'){
					t = true
				}
				return t
			}
			, is_possible_finish: function(){
				let t = false
				if(this.item.cartl_vote_base_info.cartl_vote_state_code == 'CA03700002'){
					t = true
				}
				return t
			}
			, is_comment: function(){
				let t = false
				if(this.user.member_number && this.item.cartl_vote_base_info.comment_writing_fg == 'Y'){
					t = true
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try {
					this.$bus.$emit('on', true)

					let url = this.$api_url.api_path.get_open_voting_info
					if(this.user.member_number){
						url = this.$api_url.api_path.get_voting_info
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.item = result.data
						this.$set(this.item, 'cartl_vote_optiop_list', result.data.cartl_vote_optiop_list)

					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, getVotingInfo: async function() {
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_voting_info_user
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.item_voting = result.data
						switch (this.item_voting.requester_cartl_member_grade_code) {
							case 'CA02500001':
								break
							case 'CA02500002':
								break
							case 'CA02500003':
								if (this.item_voting.vote_release_settup_fg != 'Y') {
									this.$emit('goBack')
								}
								break
						}
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, toEdit: function(){
				this.$bus.$emit('to', { name: 'CartelVotingEdit', params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id, e_id: this.$route.params.e_id }})
			}
			, goBack: function(){
				this.$emit('goBack')
			}
			, setVote: function(item){
				if(this.item.cartl_vote_base_info.plulity_choice_fg == 'Y'){
					this.$set(item, 'is_check', !item.is_check)
				}else{
					this.item.cartl_vote_optiop_list.filter((vote) => {
						this.$set(vote, 'is_check', false)
					})
					this.$set(item, 'is_check', true)
				}
			}
			, toDetail: function(item){
				this.$bus.$emit('to', { name: 'CartelVotingItemInfo', params: { idx: this.$route.params.idx, v_id: this.$route.params.v_id, e_id: this.$route.params.e_id, o_id: item.cartl_vote_optiop_number }})
			}
			, postVote: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_voting
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
							, requester_vote_optiop_choice_list: this.member_vote_optiop_choice_number_list
						}
						, type: true
					})
					if (result.success) {
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						await this.getData()
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, update: async function(){
				this.is_on_comment = false
			}
			, onMore: function(){
				this.is_on_more = true
			}
			, onDelete: function(){
				this.is_on_delete = true
			}
			, onFinish: function(){
				this.is_on_finish = true
			}
			, postDelete: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.delete_voting
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
						this.$bus.$emit('goBack')
						this.is_on_delete = false
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
			, postFinish: async function(){
				try {
					this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_voting_finish
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_vote_board_number: this.$route.params.v_id
							, cartl_vote_number: this.$route.params.e_id
						}
						, type: true
					})
					if (result.success) {
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})

						await this.getVotingInfo()
						await this.getData()

						this.is_on_finish = false
						this.is_on_more = false
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getVotingInfo()
			this.getData()
		}
	}
</script>

<style>
	.progress-container line { width: 100% !important;}


	.vote-rank-tier {
		display: inline-block;
		width: 25px;
		height: 25px;
		font-size: 14px; font-weight: bold;
		line-height: 170%;
		background-color: #647572;
		color: white;
		border-radius: 100%;
		text-align: center;
	}
	.vote-rank-tier-1 {
		background-color: var(--red);
	}
	.vote-rank-tier-2 {
		background-color: var(--blue01);
	}
	.vote-rank-tier-3 {
		background-color: var(--blue01);
	}


	.vote-rank {
		padding: 10px;
		font-size: 25px; font-weight: bold;
		color: #647572;
	}
	.vote-rank-1 {
		color: var(--red);
	}
	.vote-rank-2 {
		color: var(--blue01);
	}
	.vote-rank-3 {
		color: var(--blue01);
	}
</style>